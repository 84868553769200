<template>
  <div>
    <div class="row">
      <div class="col-lg-12" >
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-8">
                <h3 class="card-category">CUSTO SEMANAL</h3>
              </div>
              <div class="col-sm-4">
                <div
                  class="btn-group btn-group-toggle"
                >
                  <!-- <button class="btn btn-sm btn-primary btn-simple">OI</button> -->
                  <button
                    title="Ver o custo da semena em curso"
                    class="btn btn-sm btn-primary btn-simple"
                    data-bs-toggle="modal"
                    data-bs-target="#semanal"
                  >
                    Custo semanal
                  </button>

                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <bar-chart
              style="height: 100%"
              chart-id="blue-bar-chart"
              :chart-data="blueBarChart.chartData"
              :gradient-stops="blueBarChart.gradientStops"
              :extra-options="blueBarChart.extraOptions"
            >
            </bar-chart>
          </div>
        </card>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-8" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">HISTÓRICO ANUAL</h5>
                <h2 class="card-title">Custo anual</h2>
              </div>
              <div class="col-4">
                 <button
                    title="Ver o histórico de pagamentos"
                    class="btn btn-sm btn-primary btn-simple"
                    data-bs-toggle="modal"
                    data-bs-target="#modalPagamentoHistorico"
                  >
                    Pagamentos
                  </button>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              chart-id="big-line-chart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>


      <!-- modal de pagementos historico -->
      <div
        class="modal fade"
        id="modalPagamentoHistorico"
        tabindex="-1"
        aria-labelledby="modalPagamentoHistorico"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalPagamentoHistorico">
                <strong>Histórico de Pagamentos ({{qtdPago}})</strong>
              </h5>
            </div>
            <div class="modal-body">
               <div class="">
            <div v-if="!hasDatas" class="row">
              <div class="alert alert-danger col-12" role="alert">
                  Sem dados por mostrar.
              </div>
            </div>

            <div v-else style="width: 100%;" class="">

              <vue-table-dynamic
                :params="paramsPagamentos"
                ref="table"
              >

              </vue-table-dynamic>
            </div>

          </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <button class="btn btn-danger " type="button" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                <!-- <button  class="btn btn-dark ml-2 ms-2"><i class="fa fa-check" aria-hidden="true"></i></button> -->
              </div>
            </div>
          </div>
        </div>
      </div>


       <!-- modal de pagementos semanais -->
      <div
        class="modal fade"
        id="semanal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                <strong>VALOR A PAGAR [{{firstday}} a {{lastday}}]</strong>
              </h5>
            </div>
            <hr>
            <div class="modal-body">
              <table class="col-7 table table-striped-sm">
                <tbody>
                  <tr>
                    <th scope="row">Custo Semanal:</th>
                    <td class="text-left">{{totalSemanal}} €</td>
                  </tr>
                  <tr>
                    <th scope="row">Dívida Anterior:</th>
                    <td>{{totalDivida}} €</td>
                  </tr>
                  <tr>
                    <th scope="row">Total a Pagar:</th>
                    <td>{{(parseFloat(totalSemanal) + parseFloat(totalDivida)).toFixed(2)}} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <div class="row">
                <button class="btn btn-danger " type="button" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                <!-- <button  class="btn btn-dark ml-2 ms-2"><i class="fa fa-check" aria-hidden="true"></i></button> -->
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import UserTable from "./Dashboard/UserTable";
import config from "@/config";
import firebase from "../firebase";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueTableDynamic from 'vue-table-dynamic'
export default {
  components: {
    LineChart,
    BarChart,
    UserTable,
    VueTableDynamic
  },

  data() {
    return {
      db: firebase.ref("/logistica"),
      dbCliente: firebase.ref("/cliente"),
      arrayDates:[],
      firstday:null,
      lastday:null,
      totalSemanal:0,
      totalDivida:0,
      totalHistorico:0,
      pagamentoHistorico:[],
      qtdPago:0,
      users: [],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{}],
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC"
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
          datasets: [
            {
              label: "Valor total (€)",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },

      paramsPagamentos: {
        data: [
          ['DATA DE PAGAMENTO', 'CUSTO DE SERVIÇO']
        ],
        header: 'row',
        enableSearch: true,
        border: true,
        stripe: true,
        pagination: true,
        pageSize: 10,
        pageSizes: [5, 10, 20, 50],
        sort: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        showCheck: true
      },


    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t("dashboard.chartCategories");
    },
    hasDatas () {
      return this.paramsPagamentos.data.length > 1
    }
  },
  methods: {

    getPaymants() {
      let _total = 0

      this.dbCliente.child(this.$session.get("key")).child("pagamentos").on("value", (items) => {

          items.forEach((item) => {
          let key = item.key;
          let data = item.val();
          this.paramsPagamentos.data.push([
            data.criado_em,
            data.custo_servico+'€',
            // data.ganho_total+'€',
            // data.taxa_total+'€'
          ]);
        });
      })
      this.qtdPago = this.paramsPagamentos.data.length - 1
    },


    getLogisticsHistorico(){
      let pagamentos = []
      let custo = 0;
      this.dbCliente.child(this.$session.get("key")).child("pagamentos").on('value',(snap)=>{
        snap.forEach(item => {
          let data = item.val();
          custo = custo + parseFloat(data.custo_servico);
          pagamentos.push(
            {
              criado_em:data.criado_em,
              custo_servico:data.custo_servico,
              ganho_total:data.ganho_total,
              taxa_total:data.taxa_total,
            }
          )
        })
      })

      this.totalHistorico = custo.toFixed(2)
      this.pagamentoHistorico = pagamentos
      this.qtdPago = pagamentos.length

    },

    initChartSemanal(){
      this.getIntervalWeek()
      var chartData = {
        // labels: this.getDaysArray(new Date(this.firstday),new Date(this.lastday)),
        datasets: [
          {
            label: "Valor total (€)",
            fill: true,
            borderColor: config.colors.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [
              this.getLogisticPriceByDate(this.arrayDates[0]),this.getLogisticPriceByDate(this.arrayDates[1]), this.getLogisticPriceByDate(this.arrayDates[2]),
              this.getLogisticPriceByDate(this.arrayDates[3]), this.getLogisticPriceByDate(this.arrayDates[4]),
              this.getLogisticPriceByDate(this.arrayDates[5]), this.getLogisticPriceByDate(this.arrayDates[6])
            ]
          }
        ]
      }
      this.blueBarChart.chartData = chartData;
      //  console.log(this.arrayDates)
    },

    //Buscar os valores das logísticas concluidas conforme a data da semana
    getLogisticPriceByDate(data_input){
      let custo = 0;
      this.db.on('value',(snap)=>{
        snap.forEach(item => {
          let data = item.val();
          if (data.confirmado_pagamento === "aguardando" && data.status === "concluido" &&
           data.id_usuario === this.$session.get("key") &&
           data.fim.substring(0,10) === data_input
          ) {
            custo = custo + parseFloat(data.pagamento);
          }
        });
      });
      return custo.toFixed(2);
    },

    //Buscar os valores das logísticas concluidas conforme o mes
    getLogisticPriceByMonth(mes){
      const current = new Date();
      let custo = 0;
      this.db.on('value',(snap)=>{
        snap.forEach(item => {
          let data = item.val();
          if (data.confirmado_pagamento != "aguardando" && data.status === "concluido" &&
           data.id_usuario === this.$session.get("key") &&
           data.fim.substring(0,7) === current.getFullYear()+"-"+mes
          ) {
            custo = custo + parseFloat(data.pagamento);
          }
        });
      });
      return custo.toFixed(2);
    },

    // Preco das logisticas concluidas na semana em curso
    getLogisticPriceByWeek(){
      let custo = 0;
      this.db.on('value',(snap)=>{
        snap.forEach(item => {
          let data = item.val();
          if (data.confirmado_pagamento === "aguardando" && data.status === "concluido" &&
           data.id_usuario === this.$session.get("key") &&
           this.arrayDates.includes(data.fim.substring(0,10)) === true
          ) {
            custo = custo + parseFloat(data.pagamento);
          }
        });
      });
      this.totalSemanal = custo.toFixed(2)
    },

    getLogisticPriceDivida(){
      let custo = 0;
      this.db.on('value',(snap)=>{
        snap.forEach(item => {
          let data = item.val();
          if (data.confirmado_pagamento === "aguardando" && data.status === "concluido" &&
           data.id_usuario === this.$session.get("key") &&
           this.arrayDates.includes(data.fim.substring(0,10)) === false
          ) {
            if(!isNaN(data.pagamento))
              custo = custo + parseFloat(data.pagamento);
          }
        });
      });
      this.totalDivida = custo.toFixed(2)
    },

    getDaysArray(start, end) {
      for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
          arr.push(this.getParseDate(new Date(dt)));
      }
      return arr;
    },
    //pegar o intervalo de dias da atual semana
    getIntervalWeek(){
      var curr = new Date;
      this.firstday = this.getParseDate(new Date(curr.setDate(curr.getDate() - curr.getDay())));
      this.lastday = this.getParseDate(new Date(curr.setDate(curr.getDate() - curr.getDay()+6)));
      this.arrayDates = this.getDaysArray(new Date(this.firstday),new Date(this.lastday))
    },
    //Formatacao de dias
    getParseDate(date_format){
      const date = date_format.getFullYear() + "-" +
      ("0" + (date_format.getMonth()+1)).slice(-2) + "-" +
      ("0" + date_format.getDate()).slice(-2)
      return date
    },
    initChartAnual() {
      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [
              this.getLogisticPriceByMonth("01"), this.getLogisticPriceByMonth("01"), this.getLogisticPriceByMonth("03"),
              this.getLogisticPriceByMonth("04"), this.getLogisticPriceByMonth("05"), this.getLogisticPriceByMonth("06"),
              this.getLogisticPriceByMonth("07"), this.getLogisticPriceByMonth("08"), this.getLogisticPriceByMonth("09"),
              this.getLogisticPriceByMonth("10"), this.getLogisticPriceByMonth("11"), this.getLogisticPriceByMonth("12")
            ]
          }
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ]
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
    }
  },
  mounted() {
   //Inicializar o grafico
   this.initChartSemanal()
   this.getLogisticPriceByWeek()
   this.getLogisticPriceDivida()
   this.initChartAnual()
  //  this.getLogisticsHistorico()
   this.getPaymants()
  },
};
</script>
<style>
</style>
